<template>
  <router-view />
</template>

<script>
import marketingCostsModule from '../../store/marketing-costs'

export default {
  beforeCreate () {
    this.$store.registerModule('marketing-costs', marketingCostsModule)
  },
  destroyed () {
    this.$store.unregisterModule('marketing-costs')
  }
}
</script>

export const MARKETING_COSTS_BATCH_DELETE_REQUEST = 'MARKETING_COSTS_BATCH_DELETE_REQUEST'
export const MARKETING_COSTS_BATCH_DELETE_SUCCESS = 'MARKETING_COSTS_BATCH_DELETE_SUCCESS'
export const MARKETING_COSTS_BATCH_DELETE_FAILURE = 'MARKETING_COSTS_BATCH_DELETE_FAILURE'

export const MARKETING_COSTS_FETCH_MANY_REQUEST = 'MARKETING_COSTS_FETCH_MANY_REQUEST'
export const MARKETING_COSTS_FETCH_MANY_SUCCESS = 'MARKETING_COSTS_FETCH_MANY_SUCCESS'
export const MARKETING_COSTS_FETCH_MANY_FAILURE = 'MARKETING_COSTS_FETCH_MANY_FAILURE'

export const MARKETING_COSTS_FETCH_REQUEST = 'MARKETING_COSTS_FETCH_REQUEST'
export const MARKETING_COSTS_FETCH_SUCCESS = 'MARKETING_COSTS_FETCH_SUCCESS'
export const MARKETING_COSTS_FETCH_FAILURE = 'MARKETING_COSTS_FETCH_FAILURE'

export const MARKETING_COSTS_SAVE_REQUEST = 'MARKETING_COSTS_SAVE_REQUEST'
export const MARKETING_COSTS_SAVE_SUCCESS = 'MARKETING_COSTS_SAVE_SUCCESS'
export const MARKETING_COSTS_SAVE_FAILURE = 'MARKETING_COSTS_SAVE_FAILURE'

export const EMPTY_MARKETING_COSTS = 'EMPTY_MARKETING_COSTS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
import {
  MARKETING_COSTS_BATCH_DELETE_REQUEST,
  MARKETING_COSTS_BATCH_DELETE_SUCCESS,
  MARKETING_COSTS_BATCH_DELETE_FAILURE,
  MARKETING_COSTS_FETCH_MANY_REQUEST,
  MARKETING_COSTS_FETCH_MANY_SUCCESS,
  MARKETING_COSTS_FETCH_MANY_FAILURE,
  MARKETING_COSTS_FETCH_REQUEST,
  MARKETING_COSTS_FETCH_SUCCESS,
  MARKETING_COSTS_FETCH_FAILURE,
  MARKETING_COSTS_SAVE_REQUEST,
  MARKETING_COSTS_SAVE_SUCCESS,
  MARKETING_COSTS_SAVE_FAILURE,

  EMPTY_MARKETING_COSTS,
  CLEAR_ERRORS
} from './types'

export default {
  fetchStatistics ({ commit }) {
    commit(MARKETING_COSTS_FETCH_REQUEST)
    return window.axios.get(`/marketing-cost/statistics`).then(response => {
      commit(MARKETING_COSTS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(MARKETING_COSTS_FETCH_FAILURE, error)
      throw error
    })
  },
  fetch ({ commit }, id) {
    commit(MARKETING_COSTS_FETCH_REQUEST)
    return window.axios.get(`/marketing-costs/${id}`).then(response => {
      commit(MARKETING_COSTS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(MARKETING_COSTS_FETCH_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(MARKETING_COSTS_FETCH_MANY_REQUEST)
    return window.axios.get(`/marketing-costs`, { params: { query, page } }).then(response => {
      commit(MARKETING_COSTS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(MARKETING_COSTS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, marketingCost) {
    const path = marketingCost.id ? `/marketing-costs/${marketingCost.id}` : '/marketing-costs'

    commit(MARKETING_COSTS_SAVE_REQUEST)
    return window.axios.post(path, marketingCost).then(response => {
      commit(MARKETING_COSTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(MARKETING_COSTS_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(MARKETING_COSTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
}
